import React from 'react';
import './String2.css';

const String2 = (props) => {
    return (
        <div className='Block13__String2-Container'>
            <div className="Block13__String2-Part1">
                <div className="Block13__String2-Part1-Left">

                </div>
                <div className="Block13__String2-Part1-Right">
                    ВК Ǩċ
                </div>
            </div>
            <div className="Block13__String2-Part2">
                Пь ↑
            </div>
            <div className="Block13__String2-Part3">
                Прзднт РФ
            </div>
            <div className="Block13__String2-Part4">
                <div className="Block13__String2-Part4-Main">
                    Свт Фдрц
                </div>
                <div className="Block13__String2-Part4-Bottom">

                </div>
            </div>
        </div>
    );
};

export default String2;