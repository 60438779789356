import React from 'react';
import './String1.css';

const String1 = (props) => {
    return (
        <div className='Block13__String1-Container'>
            <div className="Block13__String1-Part1">
                <div className="Block13__String1-Part1-Left">

                </div>
                <div className="Block13__String1-Part1-Right">
                    Прзднт РФ
                </div>
            </div>
            <div className="Block13__String1-Part2">
                Свт Фдрц
            </div>
        </div>
    );
};

export default String1;