import React from 'react';
import './String1.css';

const String1 = (props) => {
    return (
        <div className='Block9__String1-Container'>
            <div className="Block9__String1-Part1">
                <div className="Block9__String1-Part1-Header">

                </div>
                <div className="Block9__String1-Part1-Center">
                    МРОАК
                </div>
                <div className="Block9__String1-Part1-Bottom">

                </div>
            </div>
            <div className="Block9__String1-Part2">
                <div className="Block9__String1-Part2-Header">

                </div>
                <div className="Block9__String1-Part2-Center">
                    ГОФАК
                </div>
                <div className="Block9__String1-Part2-Bottom">

                </div>
            </div>
            <div className="Block9__String1-Part3">
                <div className="Block9__String1-Part3-Header">

                </div>
                <div className="Block9__String1-Part3-Center">
                    $АКИ
                </div>
                <div className="Block9__String1-Part3-Bottom">

                </div>
            </div>
        </div>
    );
};

export default String1;